.text-with-lines {
    display: flex;
    align-items: center;
    text-align: center;
  }
  
  .text-with-lines::before,
  .text-with-lines::after {
    content: '';
    border-bottom: 1px solid #000; /* Line color */
    flex-grow: 0; /* Prevents the lines from growing */
    flex-shrink: 0; /* Prevents the lines from shrinking */
    width: 185px; /* Decrease this value to shorten the line length */
  }
  
  .text-with-lines::before {
    margin-right: 5px; /* Adjust spacing around the text */
  }
  
  .text-with-lines::after {
    margin-left: 5px; /* Adjust spacing around the text */
  }
  
  .text-with-lines span {
    padding: 1rem 5px;
  }
  