
.element {
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  /* Customizing the scrollbar to be invisible */
  .element::-webkit-scrollbar {
    width: 0px !important; /* Remove scrollbar space */
    background: transparent !important; /* Optional: just make scrollbar invisible */
  }
  