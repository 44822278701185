.menu-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  
  .menu-icon {
    width: 40px;
    height: 30px;
    position: relative;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .menu-line {
    width: 100%;
    height: 2px;
    background-color: #333;
    position: absolute;
    left: 0;
    transition: all 0.2s ease-in-out;
  }
  
  .menu-line:first-child {
    top: 0;
  }
  
  .menu-line:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  
  .menu-line:last-child {
    bottom: 0;
  }
  
  .menu-navigation {
    position: fixed;
    top: 0;
    right: -200px;
    height: 100%;
    width: 200px;
    background-color: rgba(23, 42, 55, 1); /* Change to a non-transparent color */
    transition: all 0.2s ease-in-out;
    z-index: 9999; /* Add this line */

  }
  
  .menu-navigation.open {
    right: 0;
  }
  
  .close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    margin-top: 1rem;
    margin-right: 1rem;
    color: black;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  .close-btn:hover {
    color: black;
  }
  
  .menu-navigation ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30%;
    height: 100%;
  }
  
  .menu-navigation li {
    margin-bottom: 1rem;
  }
  
  .menu-navigation a {
    color: white;
    font-size: 15px;
    font-weight: bold;
    font-family: 'Helvetica Neue', sans-serif;
    text-decoration: none;
    /* text-transform: uppercase; */
    transition: all 0.2s ease-in-out;
    padding: 0.5rem;
    border-radius: 10px;
  }
  
  .menu-navigation a:hover {
    color: black;
    /* background-color: white; */
  }
  