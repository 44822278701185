/* Replace "selector" with the appropriate selector for your HTML element */
.promptBox {
    /* Add initial boxShadow style (optional) */
    /* transition: boxShadow 0.3s ease; Add a transition for smooth animation */
  }
  
  .promptBox:hover {
    /* box-shadow: 0px 3px 39px -4px rgba(25, 85, 230, 0.15); */
  }
  
  /* CSS file */
.delete-icon {
  color: gray;
  font-size: 16px;
  cursor: pointer;
  margin-left: 0.5rem;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.delete-icon:hover {
  color: linear-gradient(45deg, #007bff, #6610f2); /* Red color on hover */
}
