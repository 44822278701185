body {
  margin: 0;
  font-family: 'sans-serif', 'manrope' !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





.react-multiple-carousel__arrow::before {
  font-size: 15px !important;
  font-weight: 600 !important;
  text-align: center !important;
  color: black !important; 
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: auto;
  position: relative;
  border:'1px solid black' !important;

}

.react-multi-carousel-dot-list {
  bottom:16px 
}

.react-multi-carousel-dot button {
  height: 7px !important;
  width: 7px !important;
  border:'1px solid black' !important;

}

.react-multiple-carousel__arrow {
  background: white !important;
  border:1px solid black !important;
  
  color: black !important;
  min-width: 35px !important;
  min-height: 35px !important;
}

.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  border-right: '0px solid transparent' !important;
}

.react-pdf__Page__textContent  {
  display: none;
}
