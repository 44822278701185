/* Import the font family */
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');

/* Apply the font family to all elements */
* {
  font-family: 'Source Sans Pro', sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  /* background-image: url("/public/angola-bg1.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
}



@media only screen and (min-width: 768px) {
  .container {
    margin: 0 auto;
    max-width: 1000px;
  }
}

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    color: #fff;
    padding: 1rem;
  }
  
  .navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .navbar-nav {
    display: flex;
  }
  
 
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-grow: 1;
  }
  
  .centered-logo {
    max-width: 100%;
    height: 90px;
    width: 200px;
    /* margin-bottom: 0.2rem; */
  }
  
  /* .input-box {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    width: 100%;
    max-width: 100%;
    font-size: 0.95rem;
    
  }
   */



  
  .button {
    background-color: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    /* border: none; */
    border-radius: 0.25rem;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #555;
  }
  
  .headerLogo {
    height: 40px;
    width: 70px;
  }

  /* .tags-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }
   */


   .input-box {
    height: 64px;
    border: 1.5px solid rgba(23, 42, 55, 1);
    width: 400px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    font-size: 20px;
    color: #333;
    outline: none;
    transition: height 0.2s ease-in-out;
    box-sizing: border-box;
    overflow-y: auto;
    border-radius: 5px;
  }
  
  .tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-right: -4px;
    width: 368px;
  }


  .input-box::placeholder {
    font-weight: 100;
    color: #999999;
    font-size: 17px;
  }
  

  @media (max-width: 768px) {
    .input-box {
      width: 320px;
      font-size: 15px;
      padding: 4px;
    }
  }

  @media (max-width: 768px) {
    .input-box::placeholder {
      font-size: 15px;
    }
  }


  @media (max-width: 768px) {
    .tags-container {
      width: 297px;
      font-size: 12px;
    }
  }
  
  .tag {
    display: flex;
    align-items: center;
    background-color: rgba(23, 42, 55, 1);
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    margin: 0.3rem;
  }
  
  .tag button {
    margin-left: 8px;
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    outline: none;
  }
  
  .tag button:hover {
    text-decoration: underline;
  }
  
  .input-box:focus-within {
    border-color: #333;
  }
  
  .input-box:focus-within .tags-container {
    margin-bottom: 8px;
  }
  
  .input-box:focus-within .tags-container .tag {
    background-color: #333;
  }
  


  /* App.css */
/* App.css */




/* 
body {
  background-image: linear-gradient(to right, rgba(207, 32, 49, 0.2), rgba(255, 185, 0, 0.2)), url("bg-image-none.png");
  background-position: left;
  background-size: auto 100vh, auto;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
} */

