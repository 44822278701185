/* Replace "selector" with the appropriate selector for your HTML element */
.fileBox {
    /* Add initial boxShadow style (optional) */
    transition: boxShadow 0.3s ease; 
  }
  
  .fileBox:hover {
    box-shadow: 0px 3px 39px -4px rgba(25, 85, 230, 0.15);
  }
  
  /* CSS file */
.delete-icon {
  color: gray;
  font-size: 20px;
  cursor: pointer;
  margin-left: 0.5rem;
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.delete-icon:hover {
  color: #0262df; /* Red color on hover */
}
